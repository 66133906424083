<template>
  <el-dialog :title="dialogTitle" v-model="dialogVisible" :width="800">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-width="110px"
      v-loading="formLoading"
    >
      <el-form-item label="ID" prop="id">
        <el-input
          v-model="formData.id"
          disabled
          placeholder="id"
          class="!w-320px"
        />
      </el-form-item>
      <el-form-item label="问卷链接" prop="evalContent">
        <el-input
          v-model.trim="formData.evalContent"
          :autosize="{ minRows: 2, maxRows: 8 }"
          type="textarea"
          placeholder="请输入问卷链接"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="submitForm" type="primary" :disabled="formLoading"
        >确 定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="js">
import { ref, reactive, defineOptions, defineExpose, defineEmits } from "vue";

import { onlyUpdateEvalContent } from '@/http/api'
import { ElMessage } from "element-plus";

/** 服务确认问卷配置表单 **/
defineOptions({ name: 'SwipeCardConfirmQuestionnaireForm' })


const dialogVisible = ref(false) // 弹窗的是否展示
const dialogTitle = ref('') // 弹窗的标题
const formLoading = ref(false) // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
const formType = ref('') // 表单的类型：create - 新增；update - 修改
const formData = ref({
  id: '',
  evalContent: ''
})
const formRules = reactive({
  evalContent: [
    {
      pattern: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})(:\d{1,5})?([\/\w\.-]*)*\/?(#[\S]+)?/,
      message: '请输入正确的链接格式',
      trigger: ['blur', 'change']
    }
  ],
  id: [{ required: true, message: 'id不能为空', trigger: 'blur' }]
})
const formRef = ref() // 表单 Ref

/** 打开弹窗 */
const open = async (type, row) => {
  dialogVisible.value = true
  dialogTitle.value = type === 'create'? '新增' : type == 'update' ? '修改' : type
  formType.value = type
  resetForm()
  formData.value = row
}
defineExpose({ open }) // 提供 open 方法，用于打开弹窗

/** 提交表单 */
const emit = defineEmits(['success']) // 定义 success 事件，用于操作成功后的回调
const submitForm = async () => {
  // 校验表单
  if (!formRef.value) return
  const valid = await formRef.value.validate()
  if (!valid) return
  // 提交请求
  formLoading.value = true
  try {
    const data = formData.value
    if (formType.value === 'create') {
      // await BannerApi.createBanner(data)
      ElMessage.success('新增成功')
    } else {
      await onlyUpdateEvalContent(data)
      ElMessage.success('修改成功')
    }
    dialogVisible.value = false
    // 发送操作成功的事件
    emit('success')
  } finally {
    formLoading.value = false
  }
}

/** 重置表单 */
const resetForm = () => {
  formData.value = {
    id: '',
    evalContent: undefined
  }
  formRef.value?.resetFields()
}
</script>
