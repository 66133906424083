<template>
  <div>
    <!-- 搜索工作栏 -->
    <el-form
      class="-mb-15px"
      :model="queryParams"
      ref="queryFormRef"
      :inline="true"
      label-width="68px"
    >
      <el-form-item>
        <el-button @click="handleQuery" icon="el-icon-search">搜索</el-button>
        <el-button @click="resetQuery" icon="el-icon-refresh">重置</el-button>
        <!-- <el-button type="primary" @click="openForm('create')" v-hasPermi="['member:level:create']">
            <Icon icon="ep:plus" class="mr-5px" /> 新增
          </el-button> -->
      </el-form-item>
    </el-form>
  </div>
  <!-- 列表 -->
  <div>
    <el-table
      v-loading="loading"
      :data="list"
      :stripe="true"
      :show-overflow-tooltip="true"
    >
      <el-table-column label="ID" align="center" prop="id" min-width="60" />
      <el-table-column
        label="问卷链接"
        align="center"
        prop="evalContent"
        min-width="100"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="操作" align="center" width="110px">
        <template #default="scope">
          <el-button link type="primary" @click="openForm('update', scope.row)">
            编辑
          </el-button>
          <!-- <el-button link type="danger" @click="handleDelete(scope.row.id)"> 删除 </el-button> -->
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 表单弹窗：添加/修改 -->
  <QuestionnaireForm ref="formRef" @success="getList" />
</template>
<script lang="js" setup>
import { ref, reactive, onMounted, defineOptions } from "vue";

import { getEvalContent } from "@/http/api";
import QuestionnaireForm from "./QuestionnaireForm.vue";

/** 服务确认问卷链接配置管理 */
defineOptions({ name: "SwipeCardConfirmQuestionnaire" });

const loading = ref(true); // 列表的加载中
const list = ref([]); // 列表的数据
const queryParams = reactive({
  evalType: 13, // 配置类型：13-服务确认问卷链接配置
});
const queryFormRef = ref(); // 搜索的表单

/** 查询列表 */
const getList = async () => {
  loading.value = true;
  try {
    const data = await getEvalContent(queryParams);
    list.value = data;
  } finally {
    loading.value = false;
  }
};

/** 搜索按钮操作 */
const handleQuery = () => {
  getList();
};

/** 重置按钮操作 */
const resetQuery = () => {
  queryFormRef.value.resetFields();
  handleQuery();
};

/** 添加/修改操作 */
const formRef = ref();
const openForm = (type, row) => {
  formRef.value.open(type, row);
};


/** 初始化 */
onMounted(() => {
  getList();
});
</script>
